import 'bootstrap/js/dist/collapse';
import {MDCRipple} from '@material/ripple';
import {MDCTextField} from '@material/textfield';
import {MDCCheckbox} from '@material/checkbox';
import {MDCFormField} from '@material/form-field';
import svg4everybody from 'svg4everybody';
// import * as location_map from './location-map';
import * as favicon from './favicon';
import * as images from './images';

// guest.js
import * as favorite_place_guest  from './favorite-place-guest';
import * as register_menu         from './register-menu';

import * as plausible             from './_plausible';

const textFields = [].map.call(document.querySelectorAll('.mdc-text-field'), function(el) {
  return new MDCTextField(el);
});
const checkboxes = [].map.call(document.querySelectorAll('.mdc-checkbox'), function(el) {
  return new MDCCheckbox(el);
});

(function() {
  [].map.call(document.querySelectorAll('.mdc-form-field'), function(el) {
    let field = new MDCFormField(el);
    field.input = el.querySelectorAll('.mdc-checkbox')[0];
    return field;
  });
})();

const ripples = [].map.call(document.querySelectorAll('.mdc-button'), function(el) {
  return new MDCRipple(el);
});

svg4everybody();
