import Plausible from 'plausible-tracker'

(function() {

  const { trackPageview } = Plausible({
    domain: window.location.hostname,
    apiHost: 'https://gopowpow.com',
  });

  trackPageview();

})();
