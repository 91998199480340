import {MDCMenu, Corner} from '@material/menu';

(function() {

  let menu = document.getElementById('register-menu');
  if (!menu) {
    return;
  }

  var mdcMenu = null;

  document.getElementById('register-btn').addEventListener('click', function() {
    if (!mdcMenu) {
      mdcMenu = new MDCMenu(menu);
      mdcMenu.setAnchorCorner(Corner.TOP_END);
      menu.addEventListener('MDCMenu:selected', function(e) {
        window.location.href = e.detail.item.dataset.href;
      });  
    }
    mdcMenu.open = true;
  });

})();
