import {MDCDialog} from '@material/dialog';

(function() {
  let dlg = document.getElementById('need-registration-dialog');
  if (dlg) {
    var mdcDialog = null;
    document.getElementById('favorite-btn').addEventListener('click', function() {
      if (!mdcDialog) {
        mdcDialog = new MDCDialog(dlg);
      }
      mdcDialog.open();
    });
  }
})();
